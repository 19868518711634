import React, { useState } from "react";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";
import {
  Mail,
  Github,
  Linkedin,
  ArrowRight,
  Download,
  MapPin,
  Send
} from "lucide-react";
import { Link } from "react-router-dom";
import { skills, services, stats, workProcess } from "../static/static";

const Home = () => {
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true },
    transition: { duration: 0.6 },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.6,
        ease: "easeOut" 
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSending(true);

    emailjs
      .sendForm(
        "service_fa50uxp",
        "template_c24ji49",
        e.target,
        "kinzc2u8IJsa20nAx"
      )
      .then(
        (response) => {
          setStatusMessage("Your message has been sent successfully!");
          setFormData({ name: "", email: "", subject: "", message: "" });
          setSending(false);
        },
        (error) => {
          setStatusMessage("Something went wrong. Please try again later.");
          setSending(false);
        }
      );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-zinc-900 via-zinc-800 to-yellow-900 relative overflow-hidden">
      {/* Background Glow Effect */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-500/20 rounded-full blur-3xl"></div>
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-yellow-500/20 rounded-full blur-3xl"></div>
      </div>

      {/* Hero Section */}
      <motion.section
      className="min-h-screen flex items-center relative z-10 py-16 lg:py-24"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <motion.div
            className="flex flex-col sm:flex-row items-center gap-6 mb-12"
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="relative w-24 h-24 rounded-full overflow-hidden shadow-lg"
            >
              <img
                src="/images/profile.jpeg"
                alt="Profile"
                layout="fill"
                objectFit="cover"
                className="rounded-full"
              />
            </motion.div>
            <div className="text-center sm:text-left">
              <h2 className="text-3xl font-bold text-zinc-100">
                Jeevan Dahal
              </h2>
              <p className="text-xl text-zinc-400">Full Stack Developer</p>
            </div>
          </motion.div>

          <motion.h1
            className="text-4xl sm:text-5xl lg:text-6xl font-bold text-yellow-400 mb-6 text-center sm:text-left"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            Crafting Digital Experiences Through Code
          </motion.h1>

          <motion.p
            className="text-lg sm:text-xl text-zinc-400 mb-8 leading-relaxed text-center sm:text-left"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6 }}
          >
            With over 2 years of experience in mobile development, I help
            businesses and startups bring their ideas to life. Specialized in
            building scalable applications using modern technologies and best
            practices.
          </motion.p>

          <motion.div
            className="flex flex-col sm:flex-row gap-6 mb-12 justify-center sm:justify-start"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.8 }}
          >
            <Link href="/projects" passHref>
              <motion.a 
                className="px-6 py-3 bg-yellow-400 text-zinc-900 rounded-lg hover:bg-yellow-500 transition-colors flex items-center justify-center gap-2 font-semibold shadow-md hover:shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                View Projects <ArrowRight size={20} />
              </motion.a>
            </Link>
            <motion.button 
              className="px-6 py-3 border border-yellow-400 text-yellow-400 rounded-lg hover:bg-yellow-400/10 transition-colors inline-flex items-center justify-center gap-2 shadow-md hover:shadow-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Download CV <Download size={20} />
            </motion.button>
          </motion.div>

          <motion.div
            className="flex gap-6 justify-center sm:justify-start"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1 }}
          >
            {[
              { 
                href: "https://github.com/jeevandhl07", 
                icon: Github,
                label: "GitHub"
              },
              { 
                href: "https://www.linkedin.com/in/jeevan-dahal-5a325a279", 
                icon: Linkedin,
                label: "LinkedIn"
              },
              { 
                href: "https://mail.google.com/mail/?view=cm&fs=1&to=udsjeevan@gmail.com", 
                icon: Mail,
                label: "Email"
              }
            ].map((social) => (
              <motion.a
                key={social.label}
                href={social.href}
                className="text-zinc-400 hover:text-yellow-400 transition-colors p-2 rounded-full bg-zinc-800/50 hover:bg-zinc-700/50"
                target="_blank"
                rel="noreferrer"
                whileHover={{ 
                  scale: 1.2,
                  rotate: [0, 5, -5, 0]
                }}
                whileTap={{ scale: 0.9 }}
              >
                <social.icon size={24} />
              </motion.a>
            ))}
          </motion.div>
        </div>
      </div>
    </motion.section>

      {/* Stats Section */}
      <motion.section
        className="py-20 border-b border-zinc-800 relative z-10"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <div className="container mx-auto px-4">
          <motion.div 
            className="grid grid-cols-2 md:grid-cols-4 gap-8"
            variants={containerVariants}
          >
            {stats.map((stat, index) => (
              <motion.div
                key={stat.label}
                className="text-center bg-zinc-800/50 p-6 rounded-lg border border-zinc-700 hover:border-yellow-400 transition-all duration-300 transform hover:-translate-y-2"
                variants={itemVariants}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <stat.icon className="w-8 h-8 text-yellow-400 mx-auto mb-4" />
                <div className="text-3xl font-bold text-zinc-100 mb-2">
                  {stat.value}
                </div>
                <div className="text-zinc-400">{stat.label}</div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </motion.section>

      {/* Work Process Section */}
      <motion.section
        className="py-20 border-b border-zinc-800 relative z-10"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold text-center mb-4 text-yellow-400"
            variants={itemVariants}
          >
            How I Work
          </motion.h2>
          <motion.p
            className="text-zinc-400 text-center mb-12 max-w-2xl mx-auto"
            variants={itemVariants}
          >
            A structured approach to delivering high-quality solutions
          </motion.p>
          <motion.div 
            className="grid grid-cols-1 md:grid-cols-4 gap-8"
            variants={containerVariants}
          >
            {workProcess.map((step, index) => (
              <motion.div
                key={step.title}
                className="p-6 bg-zinc-800/50 rounded-lg border border-zinc-700 text-center transition-all duration-300 hover:border-yellow-400 hover:-translate-y-2"
                variants={itemVariants}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <step.icon className="w-8 h-8 text-yellow-400 mx-auto mb-4" />
                <h3 className="text-xl font-semibold text-zinc-100 mb-2">
                  {step.title}
                </h3>
                <p className="text-zinc-400">{step.description}</p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </motion.section>
        {/* Skills Section */}
        <motion.section
        className="py-20 border-b border-zinc-800"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold text-center mb-4 text-yellow-400"
            variants={fadeIn}
          >
            Skills & Expertise
          </motion.h2>
          <motion.p
            className="text-zinc-400 text-center mb-12 max-w-2xl mx-auto"
            variants={fadeIn}
          >
            I've worked with a variety of technologies and frameworks to deliver
            high-quality solutions.
          </motion.p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {skills.map((skill, index) => (
              <motion.div
                key={skill.name}
                className="p-6 bg-zinc-800/50 rounded-lg border border-zinc-700"
                variants={fadeIn}
              >
                <skill.icon className="w-8 h-8 text-yellow-400 mb-4" />
                <h3 className="text-xl font-semibold text-zinc-100 mb-4">
                  {skill.name}
                </h3>
                <div className="h-2 bg-zinc-700 rounded-full overflow-hidden">
                  <motion.div
                    className="h-full bg-yellow-400 rounded-full"
                    initial={{ width: 0 }}
                    whileInView={{ width: `${skill.level}%` }}
                    viewport={{ once: true }}
                    transition={{ duration: 1, delay: index * 0.1 }}
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* Services Section */}
      <motion.section
        className="py-20 border-b border-zinc-800"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold text-center mb-4 text-yellow-400"
            variants={fadeIn}
          >
            Services
          </motion.h2>
          <motion.p
            className="text-zinc-400 text-center mb-12 max-w-2xl mx-auto"
            variants={fadeIn}
          >
            I offer a range of services to help businesses succeed in the
            digital world.
          </motion.p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={service.title}
                className="p-6 bg-zinc-800/50 rounded-lg border border-zinc-700 hover:border-yellow-400 transition-colors"
                variants={fadeIn}
              >
                <service.icon className="w-8 h-8 text-yellow-400 mb-4" />
                <h3 className="text-xl font-semibold text-zinc-100 mb-4">
                  {service.title}
                </h3>
                <p className="text-zinc-400">{service.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

     
      {/* Contact Section */}
      <motion.section
        className="py-20"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-bold text-center mb-4 text-yellow-400"
            variants={fadeIn}
          >
            Get in Touch
          </motion.h2>
          <motion.p
            className="text-zinc-400 text-center mb-12 max-w-2xl mx-auto"
            variants={fadeIn}
          >
            Have a project in mind? Let's discuss how I can help bring your
            ideas to life.
          </motion.p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <motion.div
              className="p-6 bg-zinc-800/50 rounded-lg border border-zinc-700 text-center"
              variants={fadeIn}
            >
              <Mail className="w-8 h-8 text-yellow-400 mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-zinc-100 mb-2">
                Email
              </h3>
              <p className="text-zinc-400">udsjeevan@gmail.com</p>
            </motion.div>

            <motion.div
              className="p-6 bg-zinc-800/50 rounded-lg border border-zinc-700 text-center"
              variants={fadeIn}
            >
              <Linkedin className="w-8 h-8 text-yellow-400 mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-zinc-100 mb-2">
                Linkedin
              </h3>
              <p className="text-zinc-400">Jeevan Dahal</p>
            </motion.div>

            <motion.div
              className="p-6 bg-zinc-800/50 rounded-lg border border-zinc-700 text-center"
              variants={fadeIn}
            >
              <MapPin className="w-8 h-8 text-yellow-400 mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-zinc-100 mb-2">
                Location
              </h3>
              <p className="text-zinc-400">Kathmandu, Kapan</p>
            </motion.div>
          </div>

          <motion.div className="max-w-2xl mx-auto" variants={fadeIn}>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="name" className="block text-zinc-400 mb-2">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-4 py-3 bg-zinc-800/50 border border-zinc-700 rounded-lg focus:outline-none focus:border-yellow-400 text-zinc-100"
                    placeholder="Your name"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-zinc-400 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-3 bg-zinc-800/50 border border-zinc-700 rounded-lg focus:outline-none focus:border-yellow-400 text-zinc-100"
                    placeholder="Your email"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="subject" className="block text-zinc-400 mb-2">
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="w-full px-4 py-3 bg-zinc-800/50 border border-zinc-700 rounded-lg focus:outline-none focus:border-yellow-400 text-zinc-100"
                  placeholder="Project subject"
                  required
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-zinc-400 mb-2">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={6}
                  className="w-full px-4 py-3 bg-zinc-800/50 border border-zinc-700 rounded-lg focus:outline-none focus:border-yellow-400 text-zinc-100 resize-none"
                  placeholder="Your message"
                  required
                />
              </div>

              <motion.button
                type="submit"
                className="w-full px-6 py-3 bg-yellow-400 text-zinc-900 rounded-lg hover:bg-yellow-500 transition-colors flex items-center justify-center gap-2 font-semibold animate-pulse-glow"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                disabled={sending}
              >
                {sending ? "Sending..." : "Send Message"}
                <Send size={20} />
              </motion.button>
            </form>
            {statusMessage && (
              <p className="mt-4 text-center text-zinc-400">{statusMessage}</p>
            )}
          </motion.div>
        </div>
      </motion.section>
    </div>
  );
};

export default Home;