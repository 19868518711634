import React, { useState, useEffect } from "react";
import { 
  ArrowUpRight, 
  Filter, 
  Search, 
  X, 
  Github, 
  Link as LinkIcon, 
  Code, 
  Server, 
  Tag 
} from "lucide-react";
import { projects, technologies } from "../static/static";

const ProjectModal = ({ project, onClose }) => {
  if (!project) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm p-4 overflow-y-auto">
      <div className="relative w-full max-w-4xl bg-zinc-800 rounded-2xl shadow-2xl max-h-[90vh] overflow-y-auto">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 bg-zinc-700 text-zinc-300 p-2 rounded-full hover:bg-zinc-600 transition-colors z-10"
        >
          <X size={24} />
        </button>

        <div className="grid md:grid-cols-2 gap-8 p-8">
          {/* Project Image */}
          <div className="relative">
            <img 
              src={project.image} 
              alt={project.title} 
              className="w-full rounded-xl object-cover max-h-[500px] border-2 border-transparent hover:border-yellow-400/50 transition-all duration-300" 
            />
            <div className="absolute top-4 right-4 flex gap-2">
              {project.github && (
                <a
                  href={project.github}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-zinc-900/70 p-2 rounded-full hover:bg-zinc-900/90 transition-colors animate-pulse-glow"
                >
                  <Github size={20} className="text-white" />
                </a>
              )}
            </div>
          </div>

          {/* Project Details */}
          <div>
            <h2 className="text-3xl font-bold text-yellow-400 mb-4">
              {project.title}
            </h2>
            <p className="text-zinc-300 mb-6">{project.description}</p>

            {/* Detailed Sections */}
            <div className="space-y-4">
              <div>
                <h3 className="flex items-center gap-2 text-xl font-semibold text-zinc-200 mb-2">
                  <Tag size={20} className="text-yellow-400" /> Technologies
                </h3>
                <div className="flex flex-wrap gap-2">
                  {project.tech.map((tech) => (
                    <span
                      key={tech}
                      className="px-3 py-1 text-sm bg-zinc-900 text-yellow-400 rounded-full"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>

              <div>
                <h3 className="flex items-center gap-2 text-xl font-semibold text-zinc-200 mb-2">
                  <Server size={20} className="text-yellow-400" /> Features
                </h3>
                <ul className="space-y-2 text-zinc-300">
                  {project.features?.map((feature, index) => (
                    <li key={index} className="flex items-center gap-2">
                      <span className="w-2 h-2 bg-yellow-400 rounded-full"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="flex gap-4 mt-6">
                <a
                  href={project.link}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-2 px-4 py-2 bg-yellow-400 text-zinc-900 rounded-lg hover:bg-yellow-500 transition-colors animate-pulse-glow"
                >
                  <LinkIcon size={20} /> Live Demo
                </a>
                {project.github && (
                  <a
                    href={project.github}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center gap-2 px-4 py-2 border border-yellow-400 text-yellow-400 rounded-lg hover:bg-yellow-400/10 transition-colors animate-pulse-glow"
                  >
                    <Code size={20} /> Source Code
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Projects = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTech, setSelectedTech] = useState("All");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const filtered = projects.filter((project) => {
      const matchesSearch = project.title
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesTech =
        selectedTech === "All" || project.tech.includes(selectedTech);
      return matchesSearch && matchesTech;
    });
    setFilteredProjects(filtered);
  }, [searchTerm, selectedTech]);

  const clearFilters = () => {
    setSearchTerm("");
    setSelectedTech("All");
    setIsMobileFilterOpen(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-zinc-900 via-zinc-800 to-yellow-900 relative overflow-hidden">
      {/* Background Glow Effect */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-500/20 rounded-full blur-3xl"></div>
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-yellow-500/20 rounded-full blur-3xl"></div>
      </div>

      <div className="container mx-auto px-4 py-20 max-w-7xl relative z-10">
        <div className="mb-12">
          <h1 className="text-4xl font-bold text-yellow-400 mb-2">My Projects</h1>
          <p className="text-zinc-400">
            Explore my latest work and side projects
          </p>
        </div>

        {/* Mobile Filter Toggle */}
        <div className="md:hidden mb-4">
          <button 
            onClick={() => setIsMobileFilterOpen(!isMobileFilterOpen)}
            className="flex items-center gap-2 bg-zinc-800 text-zinc-300 px-4 py-2 rounded-lg animate-pulse-glow"
          >
            <Filter size={20} />
            {isMobileFilterOpen ? "Close" : "Filters"}
          </button>
        </div>

        {/* Filter Section */}
        <div className={`
          ${isMobileFilterOpen ? 'block' : 'hidden'} md:block
          mb-12 space-y-4 md:space-y-0 md:flex md:justify-between md:items-center
        `}>
          <div className="relative flex-grow md:mr-4">
            <input
              type="text"
              placeholder="Search projects..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-3 pl-10 bg-zinc-800 rounded-lg text-zinc-100 border border-zinc-700 focus:ring-2 focus:ring-yellow-400 transition-all"
            />
            <Search 
              size={20} 
              className="absolute left-3 top-1/2 -translate-y-1/2 text-zinc-400" 
            />
          </div>

          <div className="flex flex-wrap gap-2 items-center">
            {technologies.map((tech) => (
              <button
                key={tech}
                onClick={() => setSelectedTech(tech)}
                className={`
                  px-4 py-2 rounded-lg transition-colors text-sm
                  ${
                    selectedTech === tech
                      ? "bg-yellow-400 text-zinc-900 animate-pulse-glow"
                      : "bg-zinc-800 text-zinc-400 hover:bg-zinc-700"
                  }
                `}
              >
                {tech}
              </button>
            ))}
            {(searchTerm !== "" || selectedTech !== "All") && (
              <button
                onClick={clearFilters}
                className="text-zinc-400 hover:text-yellow-400 transition-colors ml-2"
              >
                <X size={24} />
              </button>
            )}
          </div>
        </div>

        {/* Projects Grid */}
        {filteredProjects.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredProjects.map((project) => (
              <div
                key={project.id}
                onClick={() => setSelectedProject(project)}
                className="bg-zinc-800 rounded-xl overflow-hidden border border-zinc-700 hover:border-yellow-400 transition-all duration-300 transform hover:-translate-y-2 hover:shadow-lg cursor-pointer group"
              >
                <div className="relative">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-48 object-cover group-hover:opacity-80 transition-opacity"
                  />
                  <div className="absolute top-4 right-4 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
                    {project.github && (
                      <a
                        href={project.github}
                        target="_blank"
                        rel="noreferrer"
                        className="bg-zinc-900/70 p-2 rounded-full hover:bg-zinc-900/90 transition-colors animate-pulse-glow"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Github size={20} className="text-white" />
                      </a>
                    )}
                  </div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-zinc-100 mb-2 flex items-center justify-between">
                    {project.title}
                    <ArrowUpRight size={20} className="text-yellow-400 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                  </h3>
                  <p className="text-zinc-400 mb-4 line-clamp-2">
                    {project.description}
                  </p>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {project.tech.map((tech) => (
                      <span
                        key={tech}
                        className="px-3 py-1 text-sm bg-zinc-900 text-yellow-400 rounded-full"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-zinc-400 py-16">
            <p>No projects found. Try adjusting your search or filters.</p>
          </div>
        )}

        {/* Project Modal */}
        {selectedProject && (
          <ProjectModal 
            project={selectedProject} 
            onClose={() => setSelectedProject(null)} 
          />
        )}
      </div>
    </div>
  );
};

export default Projects;