import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { technologies } from "../static/static";
import { Trash2, Edit, X } from "lucide-react";

// Default projects data
const defaultProjects = [
  {
    id: 1,
    title: "Portfolio Website",
    description: "Responsive personal portfolio showcasing my projects",
    tech: ["React", "Tailwind CSS", "JavaScript"],
    link: "https://myportfolio.com",
    github: "https://github.com/username/portfolio",
    image: "https://placekitten.com/800/600"
  },
  {
    id: 2,
    title: "E-commerce Platform",
    description: "Full-stack e-commerce website with payment integration",
    tech: ["React", "Node.js", "MongoDB"],
    link: "https://myecommerce.com",
    github: "https://github.com/username/ecommerce",
    image: "https://placekitten.com/800/601"
  },
  {
    id: 3,
    title: "Portfolio Website",
    description: "Responsive personal portfolio showcasing my projects",
    tech: ["React", "Tailwind CSS", "JavaScript"],
    link: "https://myportfolio.com",
    github: "https://github.com/username/portfolio",
    image: "https://placekitten.com/800/600"
  },
  {
    id: 4,
    title: "E-commerce Platform",
    description: "Full-stack e-commerce website with payment integration",
    tech: ["React", "Node.js", "MongoDB"],
    link: "https://myecommerce.com",
    github: "https://github.com/username/ecommerce",
    image: "https://placekitten.com/800/601"
  },
  {
    id: 5,
    title: "Portfolio Website",
    description: "Responsive personal portfolio showcasing my projects",
    tech: ["React", "Tailwind CSS", "JavaScript"],
    link: "https://myportfolio.com",
    github: "https://github.com/username/portfolio",
    image: "https://placekitten.com/800/600"
  },
  {
    id: 6,
    title: "E-commerce Platform",
    description: "Full-stack e-commerce website with payment integration",
    tech: ["React", "Node.js", "MongoDB"],
    link: "https://myecommerce.com",
    github: "https://github.com/username/ecommerce",
    image: "https://placekitten.com/800/601"
  }
];

const AddProject = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const [projects, setProjects] = useState(defaultProjects);
  const [editingProject, setEditingProject] = useState(null);
  const [newProject, setNewProject] = useState({
    title: "",
    description: "",
    tech: [],
    link: "",
    github: "",
    image: "",
  });
  const [imagePreview, setImagePreview] = useState(null);
  
  // New state for delete confirmation
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    project: null,
    projectNameInput: "",
    passwordInput: "",
  });

  // New state for toast notifications
  const [toast, setToast] = useState({
    message: "",
    type: "",
    isVisible: false,
  });

  // Show toast notification
  const showToast = (message, type) => {
    setToast({ message, type, isVisible: true });
    setTimeout(() => {
      setToast((prev) => ({ ...prev, isVisible: false }));
    }, 3000);
  };

  // Delete confirmation popup handler
  const handleDeleteConfirmation = (project) => {
    setDeleteConfirmation({
      isOpen: true,
      project,
      projectNameInput: "",
      passwordInput: "",
    });
  };

  // Confirm delete project
  const confirmDeleteProject = () => {
    const { project, projectNameInput, passwordInput } = deleteConfirmation;

    // Check project name and password
    if (
      projectNameInput.trim() === project.title.trim() &&
      passwordInput === "1234"
    ) {
      // Delete project
      setProjects(projects.filter((proj) => proj.id !== project.id));
      
      // Close delete confirmation
      setDeleteConfirmation({ 
        isOpen: false, 
        project: null, 
        projectNameInput: "", 
        passwordInput: "" 
      });

      // Show success toast
      showToast("Project deleted successfully!", "success");
    } else {
      // Show error toast
      showToast("Incorrect project name or password!", "error");
    }
  };

  // Render toast notification
  const renderToast = () => {
    if (!toast.isVisible) return null;

    const bgColor = 
      toast.type === "success" 
        ? "bg-green-500" 
        : "bg-red-500";

    return (
      <div 
        className={`fixed top-4 right-4 px-4 py-2 rounded-lg text-white ${bgColor} z-50 flex items-center`}
      >
        <span className="mr-2">{toast.message}</span>
        <button 
          onClick={() => setToast((prev) => ({ ...prev, isVisible: false }))}
          className="ml-2"
        >
          <X size={16} />
        </button>
      </div>
    );
  };

  // Render delete confirmation popup
  const renderDeleteConfirmation = () => {
    if (!deleteConfirmation.isOpen) return null;

    const { project } = deleteConfirmation;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-zinc-800 p-6 rounded-lg w-96 border border-zinc-700">
          <h2 className="text-2xl font-bold text-yellow-400 mb-4">
            Delete Project
          </h2>
          <p className="text-zinc-300 mb-4">
            Are you sure you want to delete the project "{project.title}"?
          </p>
          <div className="mb-4">
            <label 
              htmlFor="projectName" 
              className="block text-zinc-400 mb-2"
            >
              Type project name to confirm
            </label>
            <input
              id="projectName"
              type="text"
              value={deleteConfirmation.projectNameInput}
              onChange={(e) => 
                setDeleteConfirmation(prev => ({
                  ...prev, 
                  projectNameInput: e.target.value 
                }))
              }
              className="w-full p-3 bg-zinc-700 text-zinc-100 rounded-lg border border-zinc-600 mb-4"
              placeholder="Project name"
            />
            <label 
              htmlFor="password" 
              className="block text-zinc-400 mb-2"
            >
              Enter password
            </label>
            <input
              id="password"
              type="password"
              value={deleteConfirmation.passwordInput}
              onChange={(e) => 
                setDeleteConfirmation(prev => ({
                  ...prev, 
                  passwordInput: e.target.value 
                }))
              }
              className="w-full p-3 bg-zinc-700 text-zinc-100 rounded-lg border border-zinc-600"
              placeholder="Password"
            />
          </div>
          <div className="flex justify-between">
            <button
              onClick={() => 
                setDeleteConfirmation({ 
                  isOpen: false, 
                  project: null, 
                  projectNameInput: "", 
                  passwordInput: "" 
                })
              }
              className="px-4 py-2 bg-zinc-700 text-zinc-100 rounded-lg hover:bg-zinc-600 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={confirmDeleteProject}
              className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
            >
              Confirm Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Authentication handler
  const handleLogin = () => {
    if (password === "1234") {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };

  // Image upload handler
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setNewProject((prev) => ({ ...prev, image: reader.result }));
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  // Technology selection handler
  const handleTechSelection = (tech) => {
    setNewProject((prev) => ({
      ...prev,
      tech: prev.tech.includes(tech)
        ? prev.tech.filter((t) => t !== tech)
        : [...prev.tech, tech],
    }));
  };

  // Project submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newProject.title && newProject.description && newProject.image) {
      if (editingProject) {
        // Update existing project
        setProjects(projects.map(proj => 
          proj.id === editingProject.id ? { ...newProject, id: editingProject.id } : proj
        ));
        setEditingProject(null);
      } else {
        // Add new project
        setProjects([...projects, { ...newProject, id: Date.now() }]);
      }
      
      // Reset form
      setNewProject({
        title: "",
        description: "",
        tech: [],
        link: "",
        github: "",
        image: "",
      });
      setImagePreview(null);
    }
  };

  // Edit project handler
  const handleEditProject = (project) => {
    setEditingProject(project);
    setNewProject({ ...project });
    setImagePreview(project.image);
  };

  // Delete project handler
  const handleDeleteProject = (project) => {
    handleDeleteConfirmation(project);
  };

  // If not authenticated, show login screen
  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-zinc-900 via-zinc-800 to-yellow-900 flex items-center justify-center">
        <div className="bg-zinc-800 p-8 rounded-lg shadow-xl w-96">
          <h2 className="text-3xl font-bold text-yellow-400 mb-6 text-center">
            Project Manager
          </h2>
          <input 
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-3 bg-zinc-700 text-zinc-100 rounded-lg border border-zinc-600 mb-4"
          />
          <button 
            onClick={handleLogin}
            className="w-full px-4 py-2 bg-yellow-400 text-zinc-900 rounded-lg hover:bg-yellow-500 transition-colors"
          >
            Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-zinc-900 via-zinc-800 to-yellow-900">
      <div className="container mx-auto px-4 py-16 max-w-4xl">
        <h1 className="text-4xl font-bold text-yellow-400 mb-8">
          Project Management
        </h1>
        
        {/* Project Form */}
        <form
          onSubmit={handleSubmit}
          className="bg-zinc-800 p-6 rounded-lg border border-zinc-700 mb-8"
        >
          <h2 className="text-2xl font-semibold text-yellow-400 mb-4">
            {editingProject ? "Edit Project" : "Add New Project"}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <input
                type="text"
                placeholder="Project Title"
                value={newProject.title}
                onChange={(e) =>
                  setNewProject({ ...newProject, title: e.target.value })
                }
                className="w-full p-3 bg-zinc-700 text-zinc-100 rounded-lg border border-zinc-600 mb-4"
              />
              <textarea
                placeholder="Project Description"
                value={newProject.description}
                onChange={(e) =>
                  setNewProject({ ...newProject, description: e.target.value })
                }
                className="w-full p-3 bg-zinc-700 text-zinc-100 rounded-lg border border-zinc-600 mb-4"
              ></textarea>
              <input
                type="text"
                placeholder="Live Demo Link"
                value={newProject.link}
                onChange={(e) =>
                  setNewProject({ ...newProject, link: e.target.value })
                }
                className="w-full p-3 bg-zinc-700 text-zinc-100 rounded-lg border border-zinc-600 mb-4"
              />
              <input
                type="text"
                placeholder="GitHub Link"
                value={newProject.github}
                onChange={(e) =>
                  setNewProject({ ...newProject, github: e.target.value })
                }
                className="w-full p-3 bg-zinc-700 text-zinc-100 rounded-lg border border-zinc-600"
              />
            </div>
            <div>
              <div
                {...getRootProps()}
                className={`p-6 border-2 border-dashed rounded-lg text-center transition-colors mb-4 ${
                  isDragActive ? "border-yellow-400 bg-zinc-700" : "border-zinc-600"
                }`}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className="text-yellow-400">Drop the image here...</p>
                ) : (
                  <p className="text-zinc-400">
                    Drag & drop an image here, or click to select one
                  </p>
                )}
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="mt-4 max-w-full max-h-48 object-contain rounded-lg"
                  />
                )}
              </div>
              <div className="flex flex-wrap gap-2">
                {technologies.map((tech) => (
                  <button
                    type="button"
                    key={tech}
                    onClick={() => handleTechSelection(tech)}
                    className={`px-3 py-1 rounded-lg transition-colors ${
                      newProject.tech.includes(tech)
                        ? "bg-yellow-400 text-zinc-900"
                        : "bg-zinc-700 text-zinc-100 hover:bg-zinc-600"
                    }`}
                  >
                    {tech}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center mt-6">
            <button
              type="submit"
              className="px-4 py-2 bg-yellow-400 text-zinc-900 rounded-lg hover:bg-yellow-500 transition-colors"
            >
              {editingProject ? "Update Project" : "Add Project"}
            </button>
            {editingProject && (
              <button
                type="button"
                onClick={() => {
                  setEditingProject(null);
                  setNewProject({
                    title: "",
                    description: "",
                    tech: [],
                    link: "",
                    github: "",
                    image: "",
                  });
                  setImagePreview(null);
                }}
                className="px-4 py-2 bg-zinc-700 text-zinc-100 rounded-lg hover:bg-zinc-600 transition-colors"
              >
                Cancel
              </button>
            )}
          </div>
        </form>

        {/* Projects List */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {projects.map((project) => (
            <div 
              key={project.id} 
              className="bg-zinc-800 rounded-lg overflow-hidden shadow-lg transform transition-all hover:scale-105"
            >
              <img 
                src={project.image} 
                alt={project.title} 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-bold text-yellow-400 mb-2">
                  {project.title}
                </h3>
                <p className="text-zinc-300 mb-2">{project.description}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.tech.map((tech) => (
                    <span 
                      key={tech} 
                      className="px-2 py-1 bg-zinc-700 text-zinc-100 rounded-md text-xs"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex space-x-2">
                    {project.link && (
                      <a 
                        href={project.link} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-yellow-400 hover:text-yellow-300"
                      >
                        Demo
                      </a>
                    )}
                    {project.github && (
                      <a 
                        href={project.github} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-yellow-400 hover:text-yellow-300"
                      >
                        GitHub
                      </a>
                    )}
                  </div>
                  <div className="flex space-x-2">
                    <button 
                      onClick={() => handleEditProject(project)}
                      className="text-yellow-400 hover:text-yellow-300"
                    >
                      <Edit size={20} />
                    </button>
                    <button 
                      onClick={() => handleDeleteProject(project)}
                      className="text-red-400 hover:text-red-300"
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {renderDeleteConfirmation()}
      {renderToast()}
    </div>
  );
};

export default AddProject;