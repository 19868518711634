import {
  Code,
  Database,
  Smartphone,
  Star,
  Users,
  Coffee,
  Clock,
  Brain,
  Rocket,
  Target,
  Award,
  Gamepad,
  Music,
  Globe,
  Paintbrush,
} from "lucide-react";

export const skills = [
  { name: "Mobile", icon: Smartphone, level: 90 },
  { name: "Back-end", icon: Database, level: 80 },
  { name: "Front-end", icon: Code, level: 70 },
  //   { name: "UI/UX Design", icon: Heart, level: 75 },
  //   { name: "DevOps", icon: Rocket, level: 70 },
  //   { name: "Project Management", icon: Target, level: 85 },
];

export const services = [
  {
    title: "Mobile Development",
    description: "Creating native and cross-platform mobile applications.",
    icon: Smartphone,
  },
  {
    title: "Web Development",
    description:
      "Building responsive and scalable web applications using modern technologies.",
    icon: Code,
  },
  {
    title: "Backend Development",
    description: "Developing robust APIs and server-side applications.",
    icon: Database,
  },
  //   {
  //     title: "UI/UX Design",
  //     description: "Creating intuitive and beautiful user interfaces.",
  //     icon: Heart,
  //   },
  //   {
  //     title: "DevOps Services",
  //     description: "Setting up CI/CD pipelines and cloud infrastructure.",
  //     icon: Rocket,
  //   },
  //   {
  //     title: "Consulting",
  //     description: "Technical consultation and architecture planning.",
  //     icon: MessageSquare,
  //   },
];

export const stats = [
  { value: "15+", label: "Projects Completed", icon: Star },
  { value: "10+", label: "Happy Clients", icon: Users },
  { value: "2+", label: "Years Experience", icon: Clock },
  { value: "10+", label: "Cups of Coffee", icon: Coffee },
];

export const testimonials = [
  {
    name: "Jane Smith",
    role: "CEO, Tech Corp",
    content:
      "John delivered an exceptional e-commerce platform that exceeded our expectations.",
    image: "/api/placeholder/100/100",
  },
  {
    name: "Mike Johnson",
    role: "CTO, Startup Inc",
    content:
      "Outstanding work on our mobile app. Professional and highly skilled developer.",
    image: "/api/placeholder/100/100",
  },
  {
    name: "Sarah Williams",
    role: "Product Manager, Innovation Labs",
    content:
      "Incredible attention to detail and amazing communication throughout the project.",
    image: "/api/placeholder/100/100",
  },
];

export const workProcess = [
  {
    title: "Discovery",
    description: "Understanding your needs and project requirements",
    icon: Target,
  },
  {
    title: "Planning",
    description: "Creating detailed project roadmap and architecture",
    icon: Brain,
  },
  {
    title: "Development",
    description: "Building your solution with agile methodology",
    icon: Code,
  },
  {
    title: "Launch",
    description: "Deploying and monitoring your application",
    icon: Rocket,
  },
];

export const certifications = [
  {
    title: "AWS Certified Solutions Architect",
    organization: "Amazon Web Services",
    year: "2023",
    icon: Award,
  },
  {
    title: "Google Cloud Professional",
    organization: "Google",
    year: "2023",
    icon: Award,
  },
  {
    title: "Meta Frontend Developer",
    organization: "Meta",
    year: "2022",
    icon: Award,
  },
];

export const experiences = [
  {
    role: "Mobile Application Developer",
    company: "Bitspanda Technology",
    period: "June 2023 - Present",
    description:
      "Developing and maintaining cross-platform mobile applications while collaborating with cross-functional teams to deliver high-quality solutions.",
    highlights: [
      "Designed and developed intuitive mobile user interfaces using React Native.",
      "Optimized app performance, reducing loading time by 40%.",
      "Integrated third-party APIs to enhance application functionality.",
      "Mentored junior developers in best practices for mobile development.",
    ],
  },
  {
    role: "Junior Mobile Application Developer",
    company: "Bitspanda Technology",
    period: "February 2023 - July 2023",
    description:
      "Supported the development of mobile applications by implementing core features and fixing bugs under the guidance of senior developers.",
    highlights: [
      "Developed key application features in React Native.",
      "Contributed to bug fixes and performance improvements.",
      "Learned best practices for mobile development and team collaboration.",
    ],
  },
  {
    role: "Quality Assurance Tester",
    company: "Bitspanda Technology",
    period: "October 2022 - January 2023",
    description:
      "Ensured the quality and functionality of web and mobile applications through comprehensive testing.",
    highlights: [
      "Performed manual and automated testing for application releases.",
      "Documented and reported bugs to the development team.",
      "Collaborated with developers to resolve issues efficiently.",
    ],
  },
];

export const education = [
  {
    degree: "Bachelor's in Computer Application",
    school: "Lumbini Academic College",
    period: "Dec 2022 - Present",
    description:
      "Focused on Software Development, Computer Science, and Machine Learning with a strong emphasis on practical applications.",
  },
  {
    degree: "+2",
    school: "Reliance College",
    period: "Aug 2021 - Jun 2012",
    description:
      "Specialized in Physics and Computer Science, with a strong foundation in mathematics and programming concepts.",
  },
];

export const interests = [
  { name: "Drawing", icon: Paintbrush },
  { name: "Gaming", icon: Gamepad },
  { name: "Music", icon: Music },
  { name: "Traveling", icon: Globe },
];

export const projects = [
  {
    id: 1,
    title: "Real Estate Platform",
    description:
      "A worldwide real estate platform with property listings, advanced search filters, geolocation support, and admin management dashboard.",
    image: "/images/projectone.png",
    tech: ["MERN STACK", "Firebase"],
    link: "https://mernsite-uem0.onrender.com/",
    features: [
      "Responsive design",
      "Real-time updates",
      "User authentication",
      // Add more features
    ],
    github: null,
  },
  // {
  //   id: 2,
  //   title: "Cloud Dashboard",
  //   description:
  //     "AWS resource management system with cost tracking, resource optimization, and automated scaling features.",
  //   image: "/api/placeholder/600/400",
  //   tech: ["React", "AWS", "TypeScript"],
  //   link: "#",
  //   github: "#",
  // },
  // {
  //   id: 3,
  //   title: "IoT Monitor",
  //   description:
  //     "Real-time IoT device monitoring platform with data visualization and alert system.",
  //   image: "/api/placeholder/600/400",
  //   tech: ["React", "Python", "MQTT"],
  //   link: "#",
  //   github: "#",
  // },
  // {
  //   id: 4,
  //   title: "Task Management App",
  //   description:
  //     "Collaborative task management application with real-time updates and team features.",
  //   image: "/api/placeholder/600/400",
  //   tech: ["React", "Firebase", "Redux"],
  //   link: "#",
  //   github: "#",
  // },
  // {
  //   id: 5,
  //   title: "Social Media Dashboard",
  //   description:
  //     "Analytics dashboard for social media management with scheduling capabilities.",
  //   image: "/api/placeholder/600/400",
  //   tech: ["React", "Node.js", "PostgreSQL"],
  //   link: "#",
  //   github: "#",
  // },
];

export const technologies = [
  "All",
  "MongoDB",
  "MERN STACK",
  "React",
  "Node.js",
  "JavaScript",
  "Firebase",
];
