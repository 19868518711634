import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Header, Footer } from './components/Layout';
import Home from './pages/Home';
import { Contact } from './pages/Contact';
import Projects from './pages/Projects';
import About from './pages/About';
import AddProject from './pages/add-project';

const App = () => {
  const location = useLocation();

  // Pages where the header and footer should not appear
  const hideHeaderFooter = location.pathname === '/add-project';

  return (
    <div className="flex flex-col min-h-screen bg-zinc-900">
      {!hideHeaderFooter && <Header />}
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/add-project" element={<AddProject />} />
        </Routes>
      </main>
      {!hideHeaderFooter && <Footer />}
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
