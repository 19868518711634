import React, { useState } from "react";
import { Mail, Linkedin, Github, Send, MapPin } from "lucide-react";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";

export const Contact = () => {
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSending(true);

    emailjs
      .sendForm(
        "service_fa50uxp", // Replace with your EmailJS service ID
        "template_c24ji49",
        e.target, // Form reference
        "kinzc2u8IJsa20nAx" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          setStatusMessage("Your message has been sent successfully!");
          setFormData({ name: "", email: "", subject: "", message: "" });
          setSending(false);
        },
        (error) => {
          setStatusMessage("Something went wrong. Please try again later.");
          setSending(false);
        }
      );
  };

  return (
    <motion.div
      className="min-h-screen bg-gradient-to-br from-zinc-900 via-zinc-800 to-yellow-900 relative overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {/* Background Glow Effect */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-500/20 rounded-full blur-3xl"></div>
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-yellow-500/20 rounded-full blur-3xl"></div>
      </div>

      <div className="container mx-auto px-4 max-w-6xl py-24 relative z-10">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="space-y-12"
        >
          {/* Header Section */}
          <motion.div
            variants={fadeIn}
            className="text-center max-w-2xl mx-auto"
          >
            <h1 className="text-4xl md:text-5xl font-bold text-yellow-400 mb-4">
              Get in Touch
            </h1>
            <p className="text-zinc-400 text-lg">
              Have a project in mind? Let's discuss how we can work together to
              bring your ideas to life.
            </p>
          </motion.div>

          {/* Contact Info Cards */}
          <motion.div
            variants={fadeIn}
            className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12"
          >
            <div className="p-6 bg-zinc-800/50 rounded-lg border border-zinc-700 text-center">
              <Mail className="w-8 h-8 text-yellow-400 mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-zinc-100 mb-2">
                Email
              </h3>
              <p className="text-zinc-400">udsjeevan@gmail.com</p>
            </div>

            <div className="p-6 bg-zinc-800/50 rounded-lg border border-zinc-700 text-center">
              <MapPin className="w-8 h-8 text-yellow-400 mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-zinc-100 mb-2">
                Location
              </h3>
              <p className="text-zinc-400">Kathmandu, Kapan</p>
            </div>

            <div className="p-6 bg-zinc-800/50 rounded-lg border border-zinc-700 text-center">
              <div className="flex justify-center gap-4 text-yellow-400">
                <a
                  href="https://github.com/jeevandhl07"
                  className="text-zinc-400 hover:text-yellow-400 transition-colors hover:animate-pulse-glow"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Github size={32} />
                </a>
                <a
                  href="https://www.linkedin.com/in/jeevan-dahal-5a325a279"
                  className="text-zinc-400 hover:text-yellow-400 transition-colors hover:animate-pulse-glow"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Linkedin size={32} />
                </a>
                <a
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=udsjeevan@gmail.com"
                  className="text-zinc-400 hover:text-yellow-400 transition-colors hover:animate-pulse-glow"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Mail size={32} />
                </a>
              </div>
              <h3 className="text-xl font-semibold text-zinc-100 mt-4 mb-2">
                Social Media
              </h3>
              <p className="text-zinc-400">Connect with me online</p>
            </div>
          </motion.div>

          {/* Contact Form Section */}
          <motion.div variants={fadeIn} className="max-w-2xl mx-auto">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="name" className="block text-zinc-400 mb-2">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-4 py-3 bg-zinc-800/50 border border-zinc-700 rounded-lg focus:outline-none focus:border-yellow-400 text-zinc-100"
                    placeholder="Your name"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-zinc-400 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-3 bg-zinc-800/50 border border-zinc-700 rounded-lg focus:outline-none focus:border-yellow-400 text-zinc-100"
                    placeholder="Your email"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="subject" className="block text-zinc-400 mb-2">
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="w-full px-4 py-3 bg-zinc-800/50 border border-zinc-700 rounded-lg focus:outline-none focus:border-yellow-400 text-zinc-100"
                  placeholder="What's this about?"
                  required
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-zinc-400 mb-2">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={6}
                  className="w-full px-4 py-3 bg-zinc-800/50 border border-zinc-700 rounded-lg focus:outline-none focus:border-yellow-400 text-zinc-100 resize-none"
                  placeholder="Your message"
                  required
                />
              </div>

              <motion.button
                type="submit"
                className="w-full px-6 py-3 bg-yellow-400 text-zinc-900 rounded-lg hover:bg-yellow-500 transition-colors flex items-center justify-center gap-2 font-semibold animate-pulse-glow"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                disabled={sending}
              >
                {sending ? "Sending..." : "Send Message"}
                <Send size={20} />
              </motion.button>
            </form>
            {statusMessage && (
              <p className="mt-4 text-center text-zinc-400">{statusMessage}</p>
            )}
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};