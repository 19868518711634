import React from "react";
import { motion } from "framer-motion";
import { Heart, Globe, Coffee, MapPin, Award, Code, Zap } from "lucide-react";
import { experiences, interests } from "../static/static";

const About = () => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.2 }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-zinc-900 via-zinc-800 to-yellow-900 relative overflow-hidden">
      {/* Background Glow Effect */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-500/20 rounded-full blur-3xl"></div>
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-yellow-500/20 rounded-full blur-3xl"></div>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.section 
          className="max-w-6xl mx-auto pt-32 pb-20"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Profile Header */}
          <motion.div 
            className="flex flex-col md:flex-row gap-12 mb-16"
            variants={itemVariants}
            whileHover="hover"
          >
            <div className="md:w-1/3">
            <motion.div 
  initial={{ scale: 0 }}
  animate={{ scale: 1 }}
  transition={{ 
    type: "spring", 
    stiffness: 260, 
    damping: 20 
  }}
  className="relative"
>
  <img
    src="/images/profile.jpeg"
    alt="Profile"
    className="w-56 h-56 rounded-full bg-yellow-400 mb-6 object-cover shadow-2xl hover:scale-105 transition-transform duration-300"
  />
</motion.div>

              <h1 className="text-4xl font-bold text-zinc-100 mb-2">
                Jeevan Dahal
              </h1>
              <motion.p 
                className="text-yellow-400 mb-4"
                variants={itemVariants}
              >
                Full Stack Developer
              </motion.p>
              <motion.div 
                className="flex items-center text-zinc-400 mb-6"
                variants={itemVariants}
              >
                <MapPin className="mr-2" />
                Kathmandu, Kapan
              </motion.div>
              <motion.div 
                className="flex gap-6 text-zinc-400"
                variants={itemVariants}
              >
                <Coffee className="w-7 h-7 hover:text-yellow-400 transition-colors" />
                <Heart className="w-7 h-7 hover:text-red-500 transition-colors" />
                <Globe className="w-7 h-7 hover:text-green-400 transition-colors" />
              </motion.div>
            </div>

            {/* About Me Content */}
            <motion.div 
              className="md:w-2/3 space-y-6"
              variants={itemVariants}
            >
              <h2 className="text-3xl font-bold text-yellow-400 mb-6">
                About Me
              </h2>
              <motion.p 
                className="text-zinc-300 leading-relaxed"
                variants={itemVariants}
              >
                I'm a passionate Full Stack Developer with over 2 years of
                experience in creating web and mobile applications. My journey
                in technology started with a curiosity about how things work on
                the internet, which led me to pursue a career in software
                development.
              </motion.p>
              <motion.p 
                className="text-zinc-300 leading-relaxed"
                variants={itemVariants}
              >
                I specialize in building scalable applications using modern
                technologies and best practices. My approach combines technical
                expertise with creative problem-solving to deliver solutions
                that not only work well but also provide an excellent user
                experience.
              </motion.p>
              
              {/* Quick Info Grid */}
              <motion.div 
                className="grid grid-cols-2 gap-6 pt-6 border-t border-zinc-700"
                variants={containerVariants}
              >
                {[
                  { icon: MapPin, label: "Location", value: "Kathmandu, Kapan" },
                  { icon: Zap, label: "Experience", value: "2+ Years" },
                  { icon: Award, label: "Available for", value: "Freelance & Full-time" },
                  { icon: Code, label: "Languages", value: "English, Nepali, Hindi" }
                ].map((info, index) => (
                  <motion.div 
                    key={index} 
                    className="bg-zinc-800/50 p-4 rounded-lg"
                    variants={itemVariants}
                    whileHover={{
                      scale: 1.05,
                      backgroundColor: "rgba(39, 39, 42, 0.7)"
                    }}
                  >
                    <info.icon className="w-6 h-6 text-yellow-400 mb-2" />
                    <h3 className="text-zinc-100 font-semibold mb-1">{info.label}</h3>
                    <p className="text-zinc-400">{info.value}</p>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          </motion.div>

          {/* Experience Section */}
          <motion.section 
            className="mb-16"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <motion.h2 
              className="text-3xl font-bold text-yellow-400 mb-12 text-center"
              variants={itemVariants}
            >
              Work Experience
            </motion.h2>
            <div className="space-y-8">
              {experiences.map((exp, index) => (
                <motion.div
                  key={index}
                  className="bg-zinc-800/50 p-6 rounded-lg"
                  variants={itemVariants}
                  whileHover={{
                    scale: 1.02,
                    boxShadow: "0 10px 15px rgba(0,0,0,0.2)"
                  }}
                >
                  <div className="flex flex-col md:flex-row gap-6">
                    <div className="md:w-1/3">
                      <h3 className="text-xl font-semibold text-zinc-100">
                        {exp.role}
                      </h3>
                      <p className="text-yellow-400">{exp.company}</p>
                      <p className="text-zinc-400">{exp.period}</p>
                    </div>
                    <div className="md:w-2/3">
                      <p className="text-zinc-300 mb-4">{exp.description}</p>
                      <ul className="list-disc list-inside space-y-2 text-zinc-400">
                        {exp.highlights.map((highlight, i) => (
                          <motion.li 
                            key={i}
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ delay: i * 0.1 }}
                          >
                            {highlight}
                          </motion.li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.section>

          {/* Interests Section */}
          <motion.section
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <motion.h2 
              className="text-3xl font-bold text-yellow-400 mb-12 text-center"
              variants={itemVariants}
            >
              Interests & Hobbies
            </motion.h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {interests.map((interest, index) => (
                <motion.div
                  key={index}
                  className="p-6 bg-zinc-800/50 rounded-lg border border-zinc-700 text-center"
                  variants={itemVariants}
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(39, 39, 42, 0.7)",
                    borderColor: "#facc15"
                  }}
                >
                  <interest.icon className="w-10 h-10 text-yellow-400 mx-auto mb-4" />
                  <h3 className="text-zinc-100">{interest.name}</h3>
                </motion.div>
              ))}
            </div>
          </motion.section>
        </motion.section>
      </div>
    </div>
  );
};

export default About;