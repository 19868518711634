import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Menu, X, ArrowUpRight } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const location = useLocation();
  
    useEffect(() => {
      const handleScroll = () => {
        setScrolled(window.scrollY > 20);
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);
  
    const navItems = [
      { name: "Home", path: "/" },
      { name: "Projects", path: "/projects" },
      { name: "About", path: "/about" },
      { name: "Contact", path: "/contact" },
    ];
  
    const menuVariants = {
      hidden: {
        opacity: 0,
        height: 0,
        transition: { duration: 0.3, ease: "easeInOut" },
      },
      visible: {
        opacity: 1,
        height: "auto",
        transition: {
          duration: 0.5,
          ease: "easeInOut",
        },
      },
    };
  
    const navItemVariants = {
      hidden: { opacity: 0, y: -10 },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          type: "spring",
          stiffness: 300,
          damping: 25,
        },
      },
      hover: {
        scale: 1.05,
        color: "#facc15", // yellow-400
      },
    };
  
    return (
      <motion.header
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={`fixed w-full top-0 z-50 transition-all duration-300 ${
          scrolled ? "bg-zinc-900/90 backdrop-blur-sm shadow-lg" : "bg-transparent"
        }`}
      >
        <nav className="w-full max-w-6xl mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <motion.div
              initial={{ rotate: -180, scale: 0 }}
              animate={{ rotate: 0, scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
            >
              <Link to="/" className="group">
                <motion.img
                  src="/images/logo.png"
                  alt="Logo"
                  whileHover={{
                    scale: 1.1,
                    rotate: [0, -10, 10, 0],
                    transition: { duration: 0.5 },
                  }}
                  className="w-12 h-12 rounded-full group-hover:shadow-lg group-hover:shadow-yellow-400/50 transition-all"
                />
              </Link>
            </motion.div>
  
            {/* Desktop Menu */}
            <div className="hidden md:flex space-x-6 items-center">
              {navItems.map((item, index) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className="text-zinc-300 relative group"
                >
                  <motion.div
                    variants={navItemVariants}
                    initial="hidden"
                    animate="visible"
                    whileHover="hover"
                    custom={index}
                    className="flex flex-col items-center"
                  >
                    {item.name}
                    <motion.span
                      layoutId={`underline-${item.name}`}
                      className={`absolute left-0 bottom-0 h-0.5 bg-yellow-400 transition-all duration-300 
                          ${
                            location.pathname === item.path
                              ? "w-full"
                              : "w-0 group-hover:w-full"
                          }`}
                    />
                  </motion.div>
                </Link>
              ))}
              <motion.a
                href="/resume.pdf"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-yellow-400 text-zinc-900 px-4 py-2 rounded-full flex items-center space-x-2 text-sm font-medium hover:bg-yellow-300 transition-colors"
              >
                <span>Resume</span>
                <ArrowUpRight size={16} />
              </motion.a>
            </div>
  
            {/* Mobile Menu Button */}
            <motion.button
              whileTap={{ scale: 0.9 }}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden text-zinc-300 focus:outline-none"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </motion.button>
          </div>
        </nav>
  
        {/* Mobile Menu */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={menuVariants}
              className="md:hidden bg-zinc-900 border-b border-zinc-800 overflow-hidden"
            >
              <div className="w-full max-w-6xl mx-auto px-4 py-2">
                {navItems.map((item, index) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <motion.div
                      className="block px-4 py-3 text-zinc-300 hover:bg-zinc-800 rounded-lg transition-colors"
                      variants={navItemVariants}
                      custom={index}
                      whileHover={{
                        backgroundColor: "rgb(39 39 42)",
                        scale: 1.03,
                      }}
                    >
                      {item.name}
                    </motion.div>
                  </Link>
                ))}
                <motion.a
                  href="/resume.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block px-4 py-3 mt-2 text-zinc-300 hover:bg-zinc-800 rounded-lg transition-colors flex items-center justify-between"
                  variants={navItemVariants}
                  custom={navItems.length}
                  whileHover={{
                    backgroundColor: "rgb(39 39 42)",
                    scale: 1.03,
                  }}
                >
                  Resume
                  <ArrowUpRight size={20} />
                </motion.a>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.header>
    );
  };
  
  export default Header;
  const Footer = () => {
    return (
      <motion.footer
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-zinc-900 border-t border-zinc-800 py-8"
      >
        <div className="w-full max-w-6xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="text-center text-zinc-500 border-t border-zinc-800 pt-8"
          >
            <p>© {new Date().getFullYear()} JEEVAN07. All rights reserved.</p>
          </motion.div>
        </div>
      </motion.footer>
    );
  };
  


export { Header, Footer };